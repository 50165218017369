export enum Urls {
  APP = 'app',
  AUTH = 'auth',
  HOME = 'home',
  LOGIN = 'login',
  USER_REGISTER = 'register',
  REPORTED_ISSUES = 'reported-issues',
  RESET_PASSWORD = 'reset-password',
  PLAYGROUND = 'playground',
  GAME_COMPLETED = 'game-completed',
  GAME_HISTORY = 'game-history',
  PROFILE = 'profile',
  PLAYER_STATS = 'player-stats',
  PRACTICE_MODE = 'practice-mode',
  USER_FEEDBACK = 'user-feedback',
  VERIFY_USER = 'verify-user',
  ACTIVITY_HISTORY = 'activity-history',
  USER_REWARDS = 'user-rewards',
  TOURNAMENT_REGISTRATION = 'tournament-registration',
  LANDING_PAGE = 'landing',
  ACCOUNT = 'account',
  NOT_FOUND = 'not-found',
}
